import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";
import styled from "styled-components";
import { injectGlobal } from "styled-components";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faLock, faCheck }  from "@fortawesome/fontawesome-free-solid";
import { post } from "../../common/xhr";

const ConnError = posed.div({
  enter: { bottom: 80, opacity: 1 },
  exit: { bottom: -10, opacity: 0 }
});

const TermsContainer = styled(ConnError)`
  --slab-size: 45px;
  --slab-padding: 8px;
  position: absolute;
  right: 0px;
  width: 50%;
  bottom: 0 !important;
  display: flex;
  flex-flow: column;
  height: calc(100% - var(--slab-size) - var(--slab-padding) * 2);
  justify-content: flex-start;
  z-index: 35;
  margin: 5px;
  border-radius: 10px;
  background: #082283; /* #082283fa */
  /* @supports(backdrop-filter: blur(5px)) {
    background: #082283d0;
    backdrop-filter: blur(5px) saturate(120%);
  } */

  @media all and (max-width: 600px) {
    right: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
  }
`;
const AlertError = styled.div`
  background: ${({ theme }) => theme.lightErrorColor};
  color: ${({ theme }) => theme.errorColor};
  text-align: center;
  display: block;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
`;
const MainText = styled.div`
  border-radius: 6px;
  margin: 12px;
  /* background: ${({ theme }) => theme.lightGray}; */
  color: white; /* ${({ theme }) => theme.gray}; */
  padding: 15px;
  overflow: auto;
  max-height: 60%;
  p {
    margin: 0 0 12px;
    font-size: 1rem;
    line-height: 1.125rem;
    &:last-child {
      margin: 0;
    }
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  padding: 18px 45px 0 12px;
  color: white; /* ${({ theme }) => theme.darkGray}; */
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const Auth = styled.div`
  margin: auto 12px 0;
  font-size: 0.9rem;
  label {
    margin: 6px 0;
    color: white; /* ${({ theme }) => theme.darkGray}; */
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    input[type="checkbox"] {
      display: none;
      &:checked ~ .checkbox-facade {
        color: #082283; /* ${({ theme }) => theme.primaryColor}; */
        background: ${({ theme }) => theme.lightPrimaryColor};
        border: #082283; /* ${({ theme }) => theme.primaryColor}; */
        svg {
          opacity: 1;
        }
      }
    }
    .checkbox-facade {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 20px;
      height: 20px;
      background: ${({ theme }) => theme.lightGray};
      margin-right: 8px;
      border-radius: 4px;
      border: 2px solid ${({ theme }) => theme.lightGray};
      color: ${({ theme }) => theme.darkGray};
      transition: all 150ms ease;
      svg {
        opacity: 0;
        transition: all 150ms ease;
        fill: ${({ theme }) => theme.primaryColor};
      }
      &:hover,
      &:active,
      &:focus {
        background: ${({ theme }) => theme.lightPrimaryColor};
        border: 2px solid ${({ theme }) => theme.lightGray};
      }
    }
    span {
      clear: both !important;
      color: white; /* ${({ theme }) => theme.gray};*/
      p, strong, span, div, em {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        strong {
          font-weight: 700;
        }
        em {
          font-style: italic;
        }
      }
    }
    > strong {
      width: calc(100% - 38px);
      line-height: 20px;
      font-weight: 700;
      float: left;
      margin: 5px 0;
    }
  }
`;

const Accept = styled.button`
  display: block;
  min-width: 240px;
  margin: 12px;
  padding: 6px 12px !important;
  background: ${({ theme }) => theme.lightGray };
  color: ${({ theme }) => theme.gray };
  font-weight: bold;
  font-size: 1rem;
  border: 0;
  outline: none;
  box-shadow: none;
  border-radius: 8px;
  cursor: not-allowed;
  transition: all 150ms ease;
  &.canProceedBtn {
    background: white; /* ${({ theme }) => theme.primaryColor }; */
    color: #082283fa;
    cursor: pointer;
    &:hover, &:active, &:focus, &:focus-within {
      opacity: 0.85;
    }
  }
`;

injectGlobal`
  .chat-rgpd-icon {
    display: block;
    height: 16px;
    margin: 0 14px 0 4px;
    opacity: 0.75;
  }
`;

export default class Terms extends Component {
  state = {
    currentTerms: JSON.parse(this.props.terms.replace(/(\r\n|\n|\r)/gm,"") || '{}'), // parse de carriage returns, devia estar no rails
    tickedTerms: 0,
    errorAllTermsAccepted: false,
    showTerms: false
  }

  async componentDidMount(){
    const { apiUrl, tenant } = this.props;
    const terms = this.state.currentTerms;
    const url = `${apiUrl}/aida/api/v1/rgpd`;
    const body = {
      body: JSON.stringify({ tenant, tokenConversation: this.props.roomId, termId: terms.current.id })
    };

    const prodRes = await post(`${url}/termsAccecpted`, body);
    const prodJson = await prodRes.json();

    if (prodJson && prodJson.accepted) {
      this.setState({showTerms: false}, () => {
        this.props.onAcceptTerms(true);
      });
    } else {
      this.setState({showTerms: true}, () => {
        this.props.onAcceptTerms(false);
      });
    }

    document.addEventListener('wire_ai_reset_conversation', () => {
      this.accept(false);
    });
  }

  accept = async (triggerOnAcceptTerms = true) => {
    const { apiUrl, tenant } = this.props;
    const terms = this.state.currentTerms;
    const tickedTerms = this.state.tickedTerms;

    if(!tickedTerms || tickedTerms.filter((elem)=>!elem).length > 0)
      this.setState({errorAllTermsAccepted: true});
    else {
      const url = `${apiUrl}/aida/api/v1/rgpd`;
      const body = {
        body: JSON.stringify({ tenant, tokenConversation: this.props.roomId, termId: terms.current.id })
      };

      const prodRes = await post(`${url}/acceptTerms`, body);
      const prodJson = await prodRes.json();
      if (prodJson && prodJson.success) {
        this.setState({errorAllTermsAccepted: false}, () => {
          this.setState({showTerms: false})
          this.props.onAcceptTerms(true, triggerOnAcceptTerms);
        });
      }
    }
  }

  handleGranularConsent = function(index) {
    let { tickedTerms } = this.state;
    const termsList = this.state.currentTerms.current.term_items;
    if(!tickedTerms)
      tickedTerms = Array(termsList.length).fill(false);

    tickedTerms[index] = !tickedTerms[index];

    this.setState({tickedTerms});
  }

  render(){
    let { currentTerms, errorAllTermsAccepted, tickedTerms } = this.state;


    if (!this.state.showTerms) {
      return null;
    }

    return (
      <TermsContainer key="connect-error">
        <Title>
          <FontAwesomeIcon icon={faLock} className="chat-rgpd-icon" />
          Regulamento Geral sobre a Proteção de Dados (RGPD)
        </Title>
        <MainText dangerouslySetInnerHTML={{__html: currentTerms.current.text}} />
        <Auth style={{ overflow: 'auto' }}>
          <div className="chat-rgpd-terms">
            {currentTerms.current.term_items.map((elem, index) => (
              <label>
                <input
                  type="checkbox"
                  name={elem.title}
                  onChange={()=>this.handleGranularConsent(index)}
                />
                <div className="checkbox-facade"><FontAwesomeIcon icon={faCheck} /></div>
                <strong>{elem.title}</strong>
                <span dangerouslySetInnerHTML={{__html: elem.description}} />
              </label>
            ))}
          </div>
        </Auth>
        {errorAllTermsAccepted && (!tickedTerms || tickedTerms.filter((elem)=>!elem).length > 0) && (
          <AlertError>Por favor aceite todos os termos antes de prosseguir.</AlertError>
        )}
        <Accept
          onClick={()=>this.accept()}
          className={!tickedTerms || tickedTerms.filter((elem)=>!elem).length == 0 && 'canProceedBtn'}
          children="Aceitar"
        />
      </TermsContainer>
    );
  }
}
